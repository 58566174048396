import React from "react"
// import "antd/dist/antd.css"
import MrFoodLogo from "../../components/fundamentals/logo/mr-food"

const Topbar = () => {
  return (
    <div className="w-full min-h-topbar max-h-topbar bg-primary-90 border-b border-grey-20 sticky top-0 flex items-center justify-between z-40  primary-color">
      <MrFoodLogo className="pl-[30px]" />
    </div>
  )
}

export default Topbar
